import React from 'react'
import ImageLazyLoading from './ImageLazyLoading'

function ProductCard({ data }) {
    return (
        <div className='ProductCard'>
            <div className="dt">
                <span>{data.brand}</span>
                <a href={data.link} target='_blank'>
                    <h3>{data.title}</h3>
                </a>
                <h5>{data.price}</h5>
            </div>
            <div className="image">
                <a href={data.link} target='_blank'>
                    <ImageLazyLoading source={data.image} height={400} />
                </a>
            </div>
        </div>
    )
}

export default ProductCard