import React from 'react';
import Slider from "react-slick";
import ProductCard from '../ProductCard';


const Data  = [ 
  {
    title:"Compressor Comprag Série FV",
    image:"https://www.htmarkt.hightech-airer.pt/wp-content/uploads/2024/03/FV-30.jpeg",
    brand:"Comprag",
    price:"8,456.25€ – 19,477.05€" ,
    link:"https://www.htmarkt.hightech-airer.pt/produto/compressor-elgi-serie-eg132-velocidade-variavel-2/" 
  }, 
  {
    title:"Depósito de ar comprimido Comprag",
    image:"https://www.htmarkt.hightech-airer.pt/wp-content/uploads/2024/03/RV_RGB.jpeg",
    brand:"Comprag",
    price:"928.65€ – 4,602.66€"  ,
    link:"https://www.htmarkt.hightech-airer.pt/produto/deposito-de-ar-comprimido-comprag/"
  }, 
  {
    title:"Conexão em alumínio para drenagem de condensado (rosca fêmea)",
    image:"https://www.htmarkt.hightech-airer.pt/wp-content/uploads/2021/09/90250.png",
    brand:"Elgi",
    price:"100.53€"  ,
    link:""
  }, 
  {
    title:"Tubo para ar comprimido de alumínio azul (4 e 6 metros)",
    image:"https://www.htmarkt.hightech-airer.pt/wp-content/uploads/2020/05/linha-azul.png",
    brand:"Aignep",
    price:"40.63€ – 999.76€" ,
    link:"https://www.htmarkt.hightech-airer.pt/produto/tubo-de-ar-comprimido-de-aluminio-azul/" 
  }, 
  {
    title:"Filtro de Alta Eficácia – Filtro fino",
    image:"https://www.htmarkt.hightech-airer.pt/wp-content/uploads/2021/09/90890.jpg",
    brand:"Aignep",
    price:"176.86€ – 770.20€" ,
    link:"https://www.htmarkt.hightech-airer.pt/produto/filtro-de-alta-eficacia-filtro-fino/" 
  }, 
  {
    title:"Separador água/óleo Jorc SEPREMIUM 10",
    image:"https://www.htmarkt.hightech-airer.pt/wp-content/uploads/2020/04/sepremium10.png",
    brand:"Jorc",
    price:"728.78€" ,
    link:"https://www.htmarkt.hightech-airer.pt/produto/separador-agua-oleo-jorc-sepremium-10-2/" 
  }, 
];

function LatestProducts() {
  const settings = {dots: false, infinite: true,speed: 500, slidesToShow: 3.5,
   slidesToScroll: 3.5, autoplay: true, arrows:false,
   responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1.5,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
  };
  return (
    <div className='LatestProducts'>
      <div className="wrapper">
        <div className="content">
          <div className="description">
            <h1>Produtos na nossa Loja</h1>
            <p>
            Encontre aqui a nossa seleção de produtos que temos disponíveis  neste momento. A Hightech-Airer apresenta os produtos que necessita com a 
            qualidade que procura, disponíveis em loja. Compressores de várias capacidades e desempenhos para todos os 
            tipos de aplicações, trabalho profissional ou não.
            </p>
          </div>
          <div className="slider-container">
            <Slider {...settings}>
            {Data.map((item, index)=>{ 
                 return(
                 <ProductCard data={item} key={index} />
                );
              })}    
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestProducts