import React from 'react'
import HeaderContact from './Components/ContactsComponents/HeaderContact';
import FormBox from './Components/ContactsComponents/FormBox';
import Mapcomponent from './Components/ContactsComponents/Mapcomponent';
import Navbar_hight from './Components/Navbar_hight';
import FooterHight from './Components/FooterHight';
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { LuMapPin } from "react-icons/lu";


function Contact() {
  document.title = "Hightech-Airer -  contactos";
  return (
    <div>
      <Navbar_hight active={5} />
      <HeaderContact />
      <div className="ct-box">
        <div className="info-area">
          <section>
            <h1>Informações de contacto</h1>
            <ul>
              <li><div className="icon"><FiPhoneCall /></div>+21 444 7020</li>
              <li><div className="icon"><MdOutlineMail /></div>geral@hightech-airer.pt</li>
              <li><div className="icon"><MdOutlineMail /></div>comercial@hightech-airer.pt</li>
              <li><div className="icon"><LuMapPin /></div> Portugal - Lisboa - R. Poe. José Gomes Ferreira 6 / 8, 2860-593 Moita</li>
            </ul>
          </section>
          <div className="mapb">
            <div id="embed-ded-map-canvas">
              <iframe frameborder="0"
                src="https://www.google.com/maps/embed/v1/place?q=José+Gomes+Ferreira+6+8,+2860-593+Moita+hightech&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
            </div>
          </div>
        </div>
      </div>
      <FormBox /> 
      <FooterHight />
    </div>
  )
}

export default Contact