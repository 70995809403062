import React, { useEffect } from 'react'
import Logo from './Logo'
import { Link } from 'react-router-dom'
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


function FooterHight() {

   useEffect(() => {
      window.scrollTo(0, 0)
    }, [])


   return (
      <div className='FooterHight'>
         <div className="footer-info">
            <div className="wrapper">
               <div className="box">
                  <div className="bx">
                     <div className="icon"><FaMapMarkerAlt /></div>
                     <div className="block">
                        <p>Portugal - Lisboa - R. Poe. José Gomes Ferreira 6 / 8, 2860-593 Moita</p>
                     </div>
                  </div>
                  <div className="bx">
                     <div className="icon"><MdEmail /></div>
                     <div className="block">
                        <p>geral@hightech-airer.pt</p>
                        <p>comercial@hightech-airer.pt</p> 
                     </div>
                  </div>
                  <div className="bx">
                     <div className="icon"><FaPhone /></div>
                     <div className="block">
                        <p>+21 444 7020</p> 
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="wrapper">
            <div className="content">
               <div className="text">
                  <Logo type={1} />
                  <p>
                  A empresa Hightech A.I.R.E.R. tem 13 anos, tendo sido constituída em 05/11/2010. 
                  A sua sede fica localizada em Moita. Desenvolve a sua atividade principal no âmbito de Outras máquinas e equipamentos nomeadamente equipamentos de compressores e Vácuo.
                  </p>
               </div>
               <div className="links">
                  <section className="block">
                     <h1>Sobre nós</h1>
                     <ul>
                        <li><Link to="/">Início</Link> </li>
                        <li><a target='_blank' href="https://www.htmarkt.hightech-airer.pt/products">Produtos / Loja online</a></li>
                        <li><Link to="/about">Sobre nós</Link> </li>
                        <li><Link to="/contacts">Contactos</Link> </li>
                        <li><Link to="/services">Serviços</Link> </li> 
                     </ul>
                  </section>
                  <section className="block">
                     <h1>Nossos serviços</h1>
                     <ul>
                        <li><Link to="/service_details_2">Assistência Técnica</Link> </li>
                        <li><Link to="/service_details_3">Venda de equipamento <br /> de ar comprimido</Link> </li>
                        <li><Link to="/service_details_1">Manutenção de equipamentos de ar comprimido</Link> </li>
                     </ul>
                  </section>
                  <section className="block">
                     <h1>Links utéis</h1>
                     <ul>
                        <li><Link to="#">Conta do cliente</Link> </li>
                        <li><a target='_blank' href="https://www.livroreclamacoes.pt">Livro de reclamações</a></li>
                        <li><Link to="/privacy_policy">Política de privacidade</Link> </li>
                        <li><Link to="/terms_and_conditions">Termos e  Condições</Link> </li> 
                     </ul>
                  </section>
               </div>
            </div>
         </div>
         <div className="wrapper">
            <div className="copyright">
               <div className="space">
                  <div className="flex">Copyright @2024, Hightech-Airer, Lda. Todos os direitos reservados.</div>
                  <div className="flex">
                     <a href="https://www.facebook.com/hightechmoita?locale=pt_PT" target='_blank' className="icon"><FaFacebook /></a>
                     <a href="https://pt.linkedin.com/company/hightech-airer-solu%C3%A7%C3%B5es-%C3%A0-sua-medida?trk=public_profile_topcard-current-company" target='_blank' className="icon"><FaLinkedin /></a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default FooterHight