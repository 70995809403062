import React from 'react'
import Form from 'react-bootstrap/Form';

function FormBox() {
    return (
        <div className='FormBox'>
            <div className="wrapper">
                <div className="content">
                    <div className="form-box">
                        <h1>Envie-nos uma mensagem</h1>
                        <Form>
                            <div className="flex-box">
                                <Form.Group className="mb-3 f" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nome *</Form.Label>
                                    <Form.Control type="text" required />
                                </Form.Group>
                                <div className="pd"></div>
                                <Form.Group className="mb-3 f" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email *</Form.Label>
                                    <Form.Control type="email" required />
                                </Form.Group>
                            </div>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Descreva o assunto *</Form.Label>
                                <Form.Control as="textarea" required rows={3} />
                            </Form.Group>
                            <button className="btn bg-main text-light">Enviar</button>
                        </Form>
                    </div>
                </div>
            </div>
            <br /><br />
        </div>
    )
}

export default FormBox