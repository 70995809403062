import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalAlert() {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        setTimeout(() => {
            handleClose();
        }, 4000);
    },[]);

    
 
    return (
        <div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}  >
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Notificação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                     <p className="mt-2">
                         Seja Bem vindo ! <br />
                         Porque queremos continuar a manter a sua confiança,  estamos a promover alterações de imagem e organização . <br />
                         Prometemos ser Breves.
                     </p>
                </Modal.Body> 
            </Modal>
        </div>
    )
}

export default ModalAlert