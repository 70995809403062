import React from 'react'
import FooterHight from '../Components/FooterHight'
import Navbar_hight from '../Components/Navbar_hight'
import ImageLazyLoading from '../Components/ImageLazyLoading'
import { Link } from 'react-router-dom'

function ServicePage2() {
    document.title = "Manutenção de equipamentos de ar comprimido"
    return (
        <div>
            <Navbar_hight active={4} />
            <section className="service-details">
                <div className="wrapper">
                    <aside>
                        <h1 className="title">Assistência  Técnica</h1>
                        <div className="box">
                            <strong>Oferecemos soluções eficazes e rapidas para os seus probelmas</strong><br />
                            Através de uma Equipa de Assistência Técnica permanente, com técnicos qualificados e experientes, associando uma
                            eficaz gestão dos dispositivos  e peças de reserva originais, oferecemos segurança e rapidez de resposta.
                            Os nossos Serviços Técnicos, prestam localmente e não só, todas as informações necessárias, para uma eficaz instalação e montagem dos Equipamentos.
                        </div>
                        <ImageLazyLoading source="https://static.ferramentaskennedy.com.br/storage/assets/como-usar-um-compressor-de-ar-confira-dicas554ef07a5662d3fd813a65c80a17f47a.jpg" />
                        <p>
                            <strong>Problemas comuns com sistemas de ar comprimido</strong><br />
                            Sólidos, líquidos e vapores de gás podem afetar o funcionamento eficiente e suave do seu sistema de compressor de ar, com problemas particularmente comuns causados por partículas, acumulação de humidade e contaminação por lubrificação.
                            <br /><br />
                            Fornecemos  assistência técnica a vários tipos de equipamentos, tais como: 
                           <strong> Compressores de Pistão e parafuso lubrificados e isentos de óleo;
                            Sistemas de Tratamento de Ar Comprimido, secadores e filtros;
                            Redes de Ar Comprimido;
                            Tratamento condensados e purgas;
                            Venda de Peças multi-marca;
                            Compressores portáteis a diesel, novos recondicionados;</strong> e muito mais.
                        </p>
                    </aside>
                </div>
            </section>
            <FooterHight />
        </div>
    )
}
export default ServicePage2