import React from 'react'
import FooterHight from '../Components/FooterHight'
import Navbar_hight from '../Components/Navbar_hight'
import ImageLazyLoading from '../Components/ImageLazyLoading'
import { Link } from 'react-router-dom'

function ServicePage3() {
    document.title = "Comercialização de equipamentos"
    return (
        <div>
            <Navbar_hight active={4} />
            <section className="service-details">
                <div className="wrapper">
                    <aside>
                        <h1 className="title">Comercialização de equipamentos</h1>
                        <div className="box">
                            <strong>Loja de Ferramentas para ferramentas   e equipamentos de Trabalho </strong><br />
                            O equipamento de ar comprimido é uma categoria onde estão incluídos todos os outros dispositivos e elementos que não podem ser classificados em outros grupos que nós oferecemos.
                            Este equipamento ajuda a melhorar a qualidade do ar e a eficiência energética do sistema do ar comprimido. O sistema de filtragem do ar de pintura é projetado para fornecer
                            ar de alta qualidade para as oficinas de pintura removendo as substâncias nocivas, que prejudicam a qualidade do desempenho do processo de fabricação.
                        </div>
                        <ImageLazyLoading source="https://www.nsckwi.com/assets/images/ramsi/aignep/3.jpg" />
                        <p> 
                            O equipamento de ar comprimido é uma categoria onde estão incluídos todos os outros dispositivos e elementos que não podem ser classificados em outros grupos que nós oferecemos. Este equipamento ajuda a melhorar a qualidade do ar e a eficiência energética do sistema do ar comprimido. O sistema de filtragem do ar de pintura é projetado para fornecer ar de alta qualidade para as oficinas de pintura removendo as substâncias nocivas, que prejudicam a qualidade do desempenho do processo de fabricação. Este é o sistema de controle de qualidade e pressão do ar comprimido. Não só o processo de fabricação, mas também a saúde do trabalhador devem ser protegidas. A empresa Omega Air d.o.o. fabrica vários sistemas de tratamento de ar de respiração, que proporcionam segurança em condições de trabalho nocivas. Os analisadores de concentração de gás monitorizam constantemente as concentrações de CO, CO2 e O2 e desencadeiam um alarme se as concentrações padrão compativeis forem excedidas.
                            <br /><br />
                            <strong> Loja de Ferramentas de  Equipamentos de Trabalho</strong><br />
                            No que diz respeito a ferramentas para oficina e equipamentos de trabalho especializados, dispomos de 400 produtos, incluindo ferramentas especializadas, sistemas de armazenamento como estantes, malas e carrinhos, sistema de etiquetagem, dispositivos de elevação e muito mais.
                            Pode ver todos os nosso produtos na loja online ou na aplicação!
                            <br /><br />
                            <strong>Tecnologia de Ar Comprimido para sua empresa</strong><br />
                            Se a sua oficina dispõe de uma infraestrutura de sistema de ar comprimido, a Hightech-Airer dispõe de equipamentos fundamentais para manutenção, substituição e tratamento de ar comprimido para tornar o seu trabalho mais eficiente e com o certificado de qualidade Hightech-Airer.
                            Os produtos Hightech-Airer para tecnologia de ar comprimido estão divididos em quatro subcategorias: conectores para ar comprimido, mangueiras de ar comprimido, tratamento de ar comprimido e pulverizadores de alta pressão para água e para líquidos oleosos.
                            Consulte na loja online os produtos inerentes a estas quatro subcategorias.
                            <br /><br />
                            Quer que não falte nada na sua oficina? Na Hightech-Airer encontra todo o equipamento necessário para que nunca lhe falte nada e para que a sua oficina funcione na perfeição.
                            Nesta secção encontrará dispositivos de serviço de óleo, nomeadamente coletores de óleo universais e gamelas para óleo especial para eixos.
                            <br /> 
                        </p>
                    </aside>
                </div>
            </section>
            <FooterHight />
        </div>
    )
}

export default ServicePage3