import React from 'react'
import ImageLazyLoading from '../ImageLazyLoading'

function HeaderServices() {
    return (
        <div className='HeaderServices'>
            <div className="wrapper">
                <div className="service-header-area">
                    <section>
                        <h1>Qualidade acima de quantidade</h1>
                        <div className="image">
                            <ImageLazyLoading source={"https://www.frane.com.br/wp-content/uploads/2022/11/04-1.jpg"} />
                        </div>
                    </section>
                    <section>
                        <p>
                            Qualidade do ar comprimido: Determine a classe de qualidade necessária para sua aplicação.
                            Vazão e pressão: Calcule a quantidade de ar comprimido (vazão) e a pressão necessária para operar suas ferramentas e equipamentos.
                            Tipo de aplicação: Escolha um compressor adequado para o tipo de trabalho que você irá realizar.
                            Orçamento: Defina o valor que você está disposto a investir em um compressor.
                        </p>
                        <div className="image">
                            <ImageLazyLoading source={"https://d91ztqmtx7u1k.cloudfront.net/ClientContent/Images/Catalogue/5-hp-elgi-direct-driven-reciprocating-air-compressor20230905104057.jpeg"} />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default HeaderServices