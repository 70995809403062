import React from 'react'
import Navbar_hight from './Components/Navbar_hight';
import FooterHight from './Components/FooterHight';


function TermsAndConditions() {
    document.title = "Termos e condições";
    return (
        <div className='documentation'>
            <Navbar_hight active={3} />
            <div className='HeaderContact'>
                <div className="wrapper">
                    <div className="content">
                        <h1>Termos <span>e</span> condições</h1>
                        <p>
                            Estes termos e condições são regidos e interpretados de acordo com as leis da Hightech-Airer e o usuário do site aceita irrevogavelmente a legislação aplicada.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="docs-container">
                    <div class="row content-layout-wrapper align-items-start"> 
                        <div class="site-content col-lg-12 col-12 col-md-12" role="main"> 
                            <article id="post-3650" class="post-3650 page type-page status-publish hentry"> 
                                <div class="entry-content">
                                    <div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner"><div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element">
                                            <div class="wpb_wrapper">
                                                <p><strong>1. Termos</strong></p>
                                                <p>Ao acessar ao site Hightech-Airer, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Ao fazer uso do site, concorda que leu, entendeu e aceitou os termos, regras e condições aqui dispostos.</p>
                                                <p>Os Termos e Condições regulamentam a utilização do Site, que pertencem à Hightech-Airer. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>
                                                <p><strong>2. Uso de Licença</strong></p>
                                                <p>É concedida permissão para transferir temporariamente uma cópia dos materiais (informações ou software) no site Hightech-Airer , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, não é permitido:</p>
                                                <ul>
                                                    <li>modificar ou copiar os materiais;</li>
                                                    <li>usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
                                                    <li>tentar descompilar ou fazer engenharia reversa de qualquer software&nbsp; contido no site Hightech-Airer;</li>
                                                    <li>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou</li>
                                                    <li>transferir os materiais para outra pessoa ou ‘espelhe’ os materiais em qualquer outro servidor.</li>
                                                </ul>
                                                <p>Esta licença será automaticamente rescindida se você violar alguma das restrições referidas e poderá ser rescindida pela Hightech-Airer a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais transferidos na sua posse, seja em formato eletrónico ou impresso.</p>
                                                <p><strong>3. Isenção de responsabilidade</strong></p>
                                                <p>Os materiais no site da Hightech-Airer são fornecidos ‘como estão’. Hightech-Airer não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.</p>
                                                <p><strong>4. Limitações</strong></p>
                                                <p>Em nenhum caso a Hightech-Airer ou os seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em Hightech-Airer, mesmo que Hightech-Airer ou um representante autorizado da Hightech-Airer tenha sido notificado oralmente ou por escrito da possibilidade de tais danos.</p>
                                                <p><strong>5. Precisão dos materiais</strong></p>
                                                <p>Os materiais exibidos no site da Hightech-Airer podem incluir erros técnicos, tipográficos ou fotográficos. Hightech-Airer não garante que qualquer material em seu site seja preciso, completo ou atual. Hightech-Airer pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, a Hightech-Airer não se compromete a atualizar os materiais.</p>
                                                <p><strong>6. Links</strong></p>
                                                <p>A Hightech-Airer não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso por Hightech-Airer do site. O uso de qualquer site vinculado é por conta e risco do usuário.</p>
                                                <p><strong>7. Modificações</strong></p>
                                                <p>A Hightech-Airer pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.</p>
                                                <p><strong>Lei aplicável</strong></p>
                                                <p>Estes termos e condições são regidos e interpretados de acordo com as leis da Hightech-Airer e o usuário do site aceita irrevogavelmente a legislação aplicada.</p>

                                            </div>
                                        </div>
                                    </div></div></div></div>
                                </div> 
                            </article> 
                        </div> 
                    </div>
                </div>
            </div>
            <FooterHight />
        </div>
    )
}

export default TermsAndConditions