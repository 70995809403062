import React from 'react'

function HeaderContact() {
  return (
    <div className='HeaderContact'>
        <div className="wrapper">
            <div className="content">
                <h1>Contacte-n<span>o</span>s</h1>
                <p>
                  Hightech-AIRER é uma empresa de venda e manutenção de  equipamentos de ar comprimido, 
                  remoção de fibrocimentos/amianto, automação e energias enováveis.
                  Fale com a nossa equipa para mais informações e serviços.
                </p>
            </div>
        </div>
    </div>
  )
}

export default HeaderContact