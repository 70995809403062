import { ImageGallery } from "react-image-grid-gallery";

const images = [
  {
    src: "https://isoar.pt/wp-content/uploads/2022/09/Microcilindros-AIGNEP.jpg",
    alt: "Image3's alt text",
    caption: "Hightech-Airer",
  },
  {
    src: "https://www.aignep.com/wp-content/uploads/2022/07/infinity.jpg",
    alt: "Image3's alt text",
    caption: "Hightech-Airer",
  },
  {
    src: "https://ireland.apollo.olxcdn.com/v1/files/g3d0zt3bg9hr3-PT/image;s=4208x3120",
    alt: "Image3's alt text",
    caption: "Hightech-Airer",
  },
  {
    src: "https://i.ebayimg.com/images/g/PyUAAOSwMzhk-0Nm/s-l1200.jpg",
    alt: "Image3's alt text",
    caption: "Hightech-Airer",
  },
  {
    src: "https://i.ebayimg.com/images/g/0OUAAOSwvEhcUSR-/s-l1200.webp",
    alt: "Image3's alt text",
    caption: "Hightech-Airer",
  },
  {
    src: "https://i.machineryhost.com/72ae3fde209fc2a7083c65ae54f31d18/large-used-comprag-ard-2208-compressor-2020.jpg",
    alt: "Image3's alt text",
    caption: "Hightech-Airer",
  }, 
];

function LatestWorks() {
  return (
    <div className='LatestWorks' >
      <div className="wrapper">
        <div className="content">
          <div className="description">
            <h1>Nossos ultimos trabalhos</h1>
            <p>
              Somos uma empresa especializada em fornecer serviços e produtos de ar comprimido a mais de uma decada, por meio de fornecedores nacionais e internacionais
              fornecemos os melhores serviços a bom preço e com qualidade excelente.
            </p><br />
          </div>
          <div className="images">
            <ImageGallery
              imagesInfoArray={images}
              columnCount={"auto"}
              columnWidth={450}
              gapSize={24}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestWorks