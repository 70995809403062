import React from 'react'
import FooterHight from '../Components/FooterHight'
import Navbar_hight from '../Components/Navbar_hight'
import ImageLazyLoading from '../Components/ImageLazyLoading'
import { Link } from 'react-router-dom' 

function ServicePage1() {
  document.title = "Manutenção de equipamentos de ar comprimido"
  
  return (
    <div>
        <Navbar_hight active={4} />
        <section className="service-details">
            <div className="wrapper">
                <aside>
                    <h1 className="title">Manutenção de equipamentos de ar comprimido</h1>
                    <div className="box">
                        <strong>Como melhorar e manter a qualidade do ar do seu sistema de ar comprimido</strong><br />
                        De acordo com o Compressed Air and Gas Institute, os sistemas de compressores de ar com manutenção deficiente são responsáveis por cerca de 3,2 mil milhões de dólares de custos suplementares com serviços públicos
                        e ineficiências. Manter a qualidade do  ar do seu sistema de ar comprimido pode poupar tempo e dinheiro. Porém, antes de instalar filtros e arrefecedores finais novos,
                        é importante compreender as necessidades específicas do seu compressor de ar.
                    </div>
                    <ImageLazyLoading source="https://www.lojadomecanico.com.br/blog/wp-content/uploads/2023/08/Topo-o-que-e-compressor-de-ar-ok.jpg" />
                    <p>
                        <strong>Problemas comuns com sistemas de ar comprimido</strong><br />
                        Sólidos, líquidos e vapores de gás podem afetar o funcionamento eficiente e suave do seu sistema de compressor de ar, com problemas particularmente comuns causados por partículas, acumulação de humidade e contaminação por lubrificação.
                        <br /><br />
                        <strong> Partículas</strong><br />
                        O ar contém poeiras e outros materiais sólidos pequenos. Embora isso possa não afetar muito o ambiente em geral, pode causar ineficiências nos seus sistemas de compressão de ar e levar a um desempenho inadequado.
                        Também não é fácil ver partículas. O olho humano só consegue ver partículas com cerca de 40 mícrones de espessura ou mais. Colocando em perspetiva, um único fio de cabelo humano tem cerca de 50-70 mícrones de espessura,
                        enquanto as partículas que podem afetar a qualidade do ar no seu compressor podem ser tão pequenas como 3 mícrones. Mesmo as partículas de 1/10 de mícron de tamanho podem ter efeitos adversos no seu sistema de compressão.
                        O risco de partículas associado a um sistema de compressão de ar aumenta consoante a localização e os motivos de utilização. Por exemplo, uma oficina de marcenaria ou uma loja de carpintaria produz bastante serradura,
                        com partículas mais pequenas a representar uma ameaça para o sistema de compressão. As partículas danificam sobretudo os pontos finais num sistema de compressão de ar.
                        Os compressores com um elevado volume de partículas também necessitam de mais lubrificação, o que não só é dispendioso, como também pode levar à lubrificação excessiva do sistema do compressor.
                        <br /><br />
                        <strong>A contaminação do compressor pode afetar todos os compressores</strong><br />
                        Enquanto os compressores de ar de gama mais elevada possuem mais potência e funcionalidades do que as unidades mais económicas, todos os tipos de compressores de ar correm o risco de danos materiais e de
                        apresentar uma fraca qualidade do ar devido a partículas,
                        à acumulação de humidade e à contaminação por lubrificação. Sem cuidados e manutenção adequados, os melhores compressores de ar da sua classe correm o risco de sofrer danos sem uma manutenção adequada.
                        <br /><br />
                        Alias aperiam unde asperiores. Excepturi esse eaque totam perferendis itaque accusantium ducimus saepe facere similique. Accusantium nesciunt dolor tempora
                        labore dicta quos, qui enim quibusdam molestiae culpa. Magnam odit amet pariatur harum at quae minus ipsam molestias esse, maxime molestiae sequi. Recusandae
                        nesciunt sed, corporis, ea quis modi consequuntur similique animi deleniti facere eaque perspiciatis delectus, quo omnis velit expedita facilis magnam odio
                        <br /><br />
                        <strong>Ná Hightech-Airer fazemos Um sistema limpo  um sistema de qualidade</strong><br />
                        Não há dúvida de que a manutenção regular do sistema de compressor de ar irá mantê-lo a funcionar de forma suave e mais eficiente. Mas o ponto mais importante para melhorar o filtro de ar do
                        compressor é compreender as necessidades
                        do sistema de compressão e o equipamento certo para satisfazer essas necessidades. Desta forma, pode ter um sistema de compressor de ar de alta qualidade que é económico e fácil de manter.
                        <br />
                    </p>
                </aside>
            </div>
        </section>
        <FooterHight />
    </div>
)
}
export default ServicePage1