import React from 'react'
import Navbar_hight from './Components/Navbar_hight';
import FooterHight from './Components/FooterHight';

function PrivacyPolicy() {
    document.title = "Politica de privacidade";
    return (
        <div className='documentation'>
             <Navbar_hight active={3}  />
            <div className='HeaderContact'>
                <div className="wrapper">
                    <div className="content">
                        <h1>Política <span>de</span> privacidade</h1>
                        <p>
                            A sua privacidade é importante para nós. É política da Hightech-Airer respeitar a sua
                            privacidade em relação a qualquer informação sua que possamos coletar no site <a href="https://www.hightech-airer.pt/">Hightech-Airer</a>,
                            e outros sites que possuímos e operamos.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="docs-container">
                    <div className="row content-layout-wrapper align-items-start">
                        <div className="site-content col-lg-12 col-12 col-md-12" role="main">
                            <article id="post-3" className="post-3 page type-page status-publish hentry">
                                <div className="entry-content">
                                    <div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner"><div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element">
                                            <div className="wpb_wrapper">
                                                <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos a coletar e como será usado.</p>
                                                <p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos os mesmos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizada.</p>
                                                <p>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
                                                <p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
                                                <p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>
                                                <p>O uso continuado de nosso site será considerado como aceitação das nossas práticas em torno de privacidade e informações pessoais. Se tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.</p>
                                                <h1>Política de Cookies Hightech-Airer</h1>
                                                <h1><strong>O que são cookies?</strong></h1>
                                                <p>Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos transferidos no seu computador, para melhorar a sua experiência. Esta página descreve quais informações são coletadas, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou bloquear certos elementos da funcionalidade do site.</p>
                                                <p><strong>Como usamos os cookies?</strong></p>
                                                <p>Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que deixe todos os cookies se não tiver a certeza se precisa ou não deles, caso sejam usados ​​para fornecer um serviço que usa.</p>
                                                <p><strong>Desativar cookies</strong></p>
                                                <p>Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como proceder). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.</p>
                                                <h1><strong>Cookies que definimos</strong></h1>
                                                <p><strong>Cookies relacionados à conta</strong></p>
                                                <p>Se criar uma conta connosco, usamos cookies para o gerenciamento do processo de inscrição e administração geral. Estes cookies geralmente serão excluídos quando sair do sistema, porém, em alguns casos, poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.</p>
                                                <p><strong>Cookies relacionados ao login</strong></p>
                                                <p>Utilizamos cookies quando faz login, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Estes cookies são normalmente removidos ou limpos quando efetua logout para garantir que possa acessar apenas a recursos e áreas restritas ao efetuar login.</p>
                                                <p><strong>Cookies relacionados a boletins por e-mail</strong></p>
                                                <p>Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados ​​para lembrar se já está registado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.</p>
                                                <p><strong>Pedidos processando cookies relacionados</strong></p>
                                                <p>Este site oferece a facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que o seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.</p>
                                                <p><strong>Cookies relacionados a pesquisas</strong></p>
                                                <p>Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender a nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.</p>
                                                <p><strong>Cookies relacionados a formulários</strong></p>
                                                <p>Quando envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.</p>
                                                <p><strong>Cookies de preferências do site</strong></p>
                                                <p>Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como o site é executado quando o usa. Para lembrar as suas preferências, precisamos de definir cookies para que essas informações possam ser chamadas sempre que possa interagir com uma página que seja afetada pelas suas preferências.</p>
                                                <p><strong>Cookies de Terceiros</strong></p>
                                                <p>Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A secção a seguir detalha quais cookies de terceiros pode encontrar através deste site.</p>
                                                <p>Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis ​​da Web, para nos ajudar a entender como usa o site e como podemos melhorar a sua experiência. Estes cookies podem rastrear informações como quanto tempo gasta no site e as páginas visitadas, para que possamos continuar a produzir conteúdo atraente.</p>
                                                <p>Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.</p>
                                                <p>Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. Quando ainda estamos a testar novos recursos, estes cookies podem ser usados ​​para garantir que recebe uma experiência consistente enquanto estiver no site, enquanto entendemos quais são as otimizações que os nossos usuários mais apreciam.</p>
                                                <p>À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes do nosso site realmente compram e, portanto, esse é o tipo de dados que estes cookies rastrearão. Isso é importante para si, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analisar os nossos custos de publicidade e produtos para garantir o melhor preço possível.</p>
                                                <h1>Mais informações</h1>
                                                <p>Esperaemos que esteja esclarecido/a e, como mencionado anteriormente, se houver algo que não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que usa no nosso site.</p>
                                                <p>Esta política é efetiva a partir de Maio / 2024.</p>

                                            </div>
                                        </div>
                                    </div></div></div></div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHight />
        </div>
    )
}

export default PrivacyPolicy