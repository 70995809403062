import React from 'react'
import Navbar_hight from './Components/Navbar_hight';
import FooterHight from './Components/FooterHight';
import ImageLazyLoading from './Components/ImageLazyLoading';
import BrandsSections from './Components/BrandsSections';

function About() {
    document.title = "Hightech-Airer -  sobre nós";
    return (
        <div className='About'>
            <Navbar_hight active={3} />
            <br /><br />
            <div className="about-area">
                <div className="wrapper">
                    <div className="content">

                        <div className="box">
                            <div className="text">
                                <h1>Qual é o nosso proposito</h1>
                                <p>
                                    A Hightech-Airer oferece uma ampla gama dos melhores produtos para equipamentos de compressores e vácuo,
                                    além disso, com assistência técnica de garantia insuperável. Somos uma empresa que tem parcerias com os principais distribuidores do sector em Portugal e não só, para que os nossos clientes possam ter um serviço único.
                                </p>
                            </div>
                            <div className="image">
                                <ImageLazyLoading source="https://www.aignep.com/wp-content/uploads/2022/06/valvole-pneumatiche.jpg.jpg" height={500} />
                            </div>
                        </div> 
                        <section className="block-data">
                            <aside>
                                <h1>Os Melhores parceiros no mercado</h1>
                                <p>
                                    Trabalhando com as melhores empresas de fabricação e assistência técnica de materiais de ar comprimido, como resultado desta parceria conseguimos
                                    fornecer a centenas de clientes ao longo dos últimos anos uma vasta gama de produtos, bem como assistência técnica e manutenção de equipamentos de ar comprimido.
                                </p>
                            </aside>
                            <div className="image">
                                <ImageLazyLoading source="https://i.ytimg.com/vi/CmtkZyr96jI/maxresdefault.jpg" />
                            </div>
                        </section>
                        <section className="block-data mb0 rev">
                            <aside>
                                <h1>Como são feitos os nossos serviços</h1>
                                <p>Muito mais do que vender produtos de ar comprimido  trabalhamos consigo afim de reduzir os custos da sua empresa , poís
                                    os sistemas de compressores de ar com manutenção deficiente são responsáveis por cerca de 3,2 mil milhões de dólares de custos suplementares com serviços públicos e ineficiências. Manter a qualidade do ar
                                    do seu sistema de ar comprimido pode poupar tempo e dinheiro. Porém, antes de instalar filtros e arrefecedores finais novos, é importante compreender as necessidades específicas do seu compressor de ar.
                                </p>
                            </aside>
                            <div className="image">
                                <ImageLazyLoading source="https://www.atlascopco.com/content/dam/atlas-copco/local-countries/united-kingdom/images/service%20engineer%20.jpeg/jcr:content/renditions/cq5dam.web.1200.628.jpeg" />
                            </div>
                        </section>
                        <br />
                        <BrandsSections />
                    </div>
                </div>
            </div><br />
            <FooterHight />
        </div>
    )
}

export default About