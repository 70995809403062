import React from 'react'

function WhatWeDoComponent() {
  return (
    <div className='WhatWeDoComponent'>
        <div className="wrapper">
            <div className="block-text">
                 <section className="info">
                     <h1>Saiba mais sobre os nossos serviços e produtos</h1>
                     <article>
                         <h2><div className="dt"></div> Venda de equipamentos de ar comprimido</h2>
                         <p>Vendemos todos os tipos de equipamentos de ar comprimido</p>
                     </article>
                     <article>
                         <h2><div className="dt"></div> Manutenção de equipamentos de ar comprimido</h2>
                         <p>Temos uma equipa tecnica totalmente preparada para atender as suas necessidades e desafios. </p>
                     </article>
                     <article>
                         <h2><div className="dt"></div> Assistencia Tecnica</h2>
                         <p>Muito mais do que falar , damos soluções aos nossos clientes com abordagens simples que facilitam a resolução dos seus probelmas </p>
                     </article>
                 </section>
                 <section className="description">
                    <p>os sistemas de compressores de ar com manutenção deficiente são responsáveis por cerca de 3,2 mil milhões de dólares de custos suplementares com serviços públicos e ineficiências. Manter a qualidade do ar do seu sistema de ar comprimido pode poupar tempo e dinheiro. Porém, antes de instalar filtros e arrefecedores finais novos, é importante compreender as necessidades específicas do seu compressor de ar.</p>
                    <p>O tratamento do ar comprimido é um processo fundamental, pois o ar nunca chega puro ao compressor – ele pode conter diversas substâncias indesejáveis, como água em forma de gota ou vapor, óleo em forma de gota ou aerossol, poeira, hidrocarbonetos, vírus e bactérias. </p>
                    <p>Ao respirarmos, nós aprendemos a lidar com a maioria desses elementos, mas os processos de produção da indústria costumam ser bem menos tolerantes. Afinal, dependendo da área de aplicação do ar comprimido, essas substâncias podem prejudicar muito os resultados da produção. </p>
                    <p>Por isso, o primeiro passo para garantir um tratamento adequado é identificar os requisitos de qualidade do ar da sua aplicação e a tecnologia que pode entregá-la.</p>
                 </section>
            </div>
        </div>
    </div>
  )
}

export default WhatWeDoComponent