import React from 'react'
import ImageLazyLoading from './ImageLazyLoading'
import Form from 'react-bootstrap/Form';

function NewsLetterSection() {
  return (
    <div className='NewsLetterSection'>
        <div className="wrapper">
            <div className="content">
                <div className="wall">
                    <ImageLazyLoading source="https://areacooling.com/areacademy/wp-content/uploads/2022/05/compressors.jpg" height={400} />
                </div>
                <div className="formarea">
                    <h1>Aproveite as nossas promoções e saiba mais sobre os produtos em destaque.</h1>
                    <Form> 
                       <Form.Control type="email" required placeholder="nome@exemplo.com" />
                       <button className="btn bg-main text-light">Subscrever</button>
                    </Form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsLetterSection