import React from 'react'
import { Link } from 'react-router-dom'

function NotFoundedPage() {
  return (
    <div className='notfounded'>
        <div className="wrapper">
            <h2>404</h2>
            <div><p>Pagina não encomtrada</p></div> 
            <Link to="/" className='text-main'>Voltar para pagina principal</Link>
        </div>
    </div>
  )
}

export default NotFoundedPage