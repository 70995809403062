import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from './Logo'
import { HiMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5"; 

function Navbar_hight({active}) {
 const  [ActiveMenu, setActiveMenu] = useState(false);

  return (
    <div className='navbar-hight'>
      <div className="wrapper">
        <div className="nav-items">
          <div className="items">
            <Link to="/">
              <div className="logo">
                <Logo type={1} />
              </div>
            </Link>
          </div>
          <ul className="items it">
            <li className={active === 1 ? "active" : ""}><Link to="/">Início</Link></li>
            <li className={active === 2 ? "active" : ""}><a target='_blank' href="https://www.htmarkt.hightech-airer.pt/">Produtos / Loja online</a></li> 
            <li className={active === 4 ? "active" : ""}><Link to="/services">Serviços</Link></li>
            <li className={active === 5 ? "active" : ""}><Link to="/contacts">Contactos </Link></li>
            <li className={active === 3 ? "active" : ""}><Link to="/about">Sobre nós</Link></li>
          </ul>
          <div className="items it">
            <button className="btn bg-white text-main">Login</button>
            <button className="btn bg-main text-light">Cadastro</button>
          </div>
          <div className="toggle" onClick={()=>setActiveMenu(ActiveMenu === true ? false : true)}><HiMenuAlt3 /></div>
          <div className={ActiveMenu === true ? "responsive-menu active" : "responsive-menu"} >
          <div className="toggle" onClick={()=>setActiveMenu(ActiveMenu === true ? false : true)}><IoClose /></div>
          <ul className="items">
            <li onClick={()=>setActiveMenu(ActiveMenu === true ? false : true)} className={active === 1 ? "active" : ""}><Link to="/">Início</Link></li>
            <li onClick={()=>setActiveMenu(ActiveMenu === true ? false : true)} className={active === 2 ? "active" : ""}><a target='_blank' href="https://www.htmarkt.hightech-airer.pt/">Produtos / Loja online</a></li>
            <li onClick={()=>setActiveMenu(ActiveMenu === true ? false : true)} className={active === 4 ? "active" : ""}><Link to="/services">Serviços</Link></li>
            <li onClick={()=>setActiveMenu(ActiveMenu === true ? false : true)} className={active === 5 ? "active" : ""}><Link to="/contacts">Contactos </Link></li>
            <li onClick={()=>setActiveMenu(ActiveMenu === true ? false : true)} className={active === 3 ? "active" : ""}><Link to="/about">Sobre nós</Link></li>
          </ul>
          <div className="items">
             <a  target='_blank' href="https://www.htmarkt.hightech-airer.pt/a-minha-conta/"> 
               <button className="btn bg-white text-main">Login</button>
              </a>
            <a  target='_blank' href="https://www.htmarkt.hightech-airer.pt/a-minha-conta/">
              <button className="btn bg-main text-light">Cadastro</button>
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar_hight