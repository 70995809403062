import React from 'react'
import Navbar_hight from './Components/Navbar_hight';
import FooterHight from './Components/FooterHight';
import ServicesHigh from './Components/HomeComponents/ServicesHigh';
import ImageLazyLoading from './Components/ImageLazyLoading';
import HeaderServices from './Components/ServicesComponents/HeaderServices';
import WhatWeDoComponent from './Components/ServicesComponents/WhatWeDoComponent';
import LatestProducts from './Components/HomeComponents/LatestProducts';
import BrandsSections from './Components/BrandsSections';

function Services() {
    document.title = "Hightech-Airer -  Serviços";

    return (
        <div className='Services'>
            <Navbar_hight active={4} />
            <div className="services-block">
                <HeaderServices />
                <ServicesHigh />
                <WhatWeDoComponent />
                <div className="service-information">
                    <div className="wrapper">
                        <div className="service-container">
                            <h1>Porque não existe ar de qualidade sem um tratamento eficiente.</h1>
                            <p>
                                Os modernos equipamentos de produção necessitam de Ar Comprimido nas suas mais diversas qualidades,
                                desde ar de sopro sem qualquer tratamento até ao completamente seco, isento de óleo e esterilizado.
                                As impurezas na nossa atmosfera são normalmente invisíveis a olho nu, no entanto podem impedir o normal funcionamento de
                                um sistema pneumático e equipamentos de consumo, tendo um efeito adverso na qualidade dos produtos.
                            </p>
                            <ImageLazyLoading source="https://scontent-lis1-1.xx.fbcdn.net/v/t39.30808-6/438161655_966947291885170_4819138430990085132_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeGsAmhPu5P4qwWb0XkXkxXBGVYZ562QuDsZVhnnrZC4O_-SjTd6RGsQZDL6Gb2KvFWZYcxHvPSXqLkPBWmQY8DU&_nc_ohc=HQCKsHeeBpAQ7kNvgFJZFqH&_nc_ht=scontent-lis1-1.xx&oh=00_AYCwlFPizgVwmclFo5RS3qMTEkFwsm4-eD9Z2lUh5t0Sag&oe=6664F6E8" />
                            <p>
                                De modo a corresponder a todas as necessidades do cliente , a Hightech-Airer apresenta uma gama completa de sistemas de tratamento de Ar Comprimido, consistindo em:
                                Secadores de Refrigeração, Secadores de Adsorção ,Secadores de Membrana , Filtros ,Absorvedores de Carvão Ativado
                                ,Converter, e muito mais.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHight />
        </div>
    )
}

export default Services