import React from 'react'
import ImageLazyLoading from '../ImageLazyLoading'
import { PiTrophyBold } from "react-icons/pi";
import { RiPriceTag3Line } from "react-icons/ri";
import { LiaToolsSolid } from "react-icons/lia";


function WhyHigh() {
  return (
    <div className='WhyHigh'>
      <div className="wall">
         <ImageLazyLoading source="https://ozenkompresor.com.tr/s/2527/i/how-to-calculate-the-required-air-consumption-in-the-enterprise-BLOG55_FOTO1.jpg" height={900} />
      </div>
         <div className="content">
             <div className="wrapper">
               <div className="info">
               <div className="images">
                    <div className="block">
                       <div className="img"><ImageLazyLoading source="https://www.aignep.com/wp-content/uploads/2022/07/connessioni.jpg" /></div>
                       <div className="img"><ImageLazyLoading source="https://www.prostanki.com/img/boardpics/2023_05/UkAymv1PNPpNoDfZ6B8Z.jpg" /></div>
                    </div>
                    <div className="full">
                        <div className="img"><ImageLazyLoading source="https://www.ptf-esti.pt/fotos_artigos/images/rede-distribuicao-ar-comprimido.png" height={700} /></div>
                    </div>
                </div>
                <div className="text">
                     <h1>Porque escolher-nos</h1>
                     <p>
                     Descubra a potência e confiabilidade dos compressores de ar Vendidos e reparados pela Hightech-Airer, projetados para fornecer uma fonte constante de ar comprimido em diversas aplicações.
                    </p>
                    <ul>
                         <li>
                            <div className="icon"><PiTrophyBold /></div>
                            <h4>A Melhor vendedora de ar comprimido do país</h4>
                         </li>
                         <li>
                            <div className="icon"><RiPriceTag3Line /></div>
                            <h4>Preços amigáveis e acessíveis </h4>
                         </li>
                         <li>
                            <div className="icon"><LiaToolsSolid /></div>
                            <h4>Manutenção de equipamentos rapida e  de qualidade</h4>
                         </li> 
                    </ul>
                </div>
               </div>
             </div> 
          </div> 
     </div>
  )
}

export default WhyHigh