import React from 'react'
import ImageLazyLoading from '../ImageLazyLoading'
import { Link } from 'react-router-dom'

function HeaderHigh() {
  return (
    <div className='HeaderHigh active'>
        <div className="image-wall"> 
            <div className="imageoverlay">
                 <div className="wrapper">
                     <div className="content"> 
                     <h5 className='text-main'>O seu parceiro de confiança</h5>
                          <h1>Venda, Reparação e Manutenção de <span>Equipamentos</span> de ar Comprimido e Vácuo .</h1>
                          <p>Com mais 13 anos de experiência, a Hightech-AIRER, localizada na Moita, Setúbal, é uma empresa especializada em várias soluções industriais e domésticas. Entre elas a vasta experiência em linhas de ar comprimido, compressores e secadores de ar, filtros e acessórios.</p>
                            <div className="buttons">
                               <a target='_blank' href="https://www.htmarkt.hightech-airer.pt/">
                                   <button className="btn black text-light">Visite nossa loja</button>
                               </a>
                                <Link to="/contacts">
                                  <button className="btn text-light bg-black">Entre em contacto</button>
                                </Link>
                            </div>
                     </div>
                 </div>
            </div>
        </div>
    </div>
  )
}

export default HeaderHigh