import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Services from './Pages/Services';
import ServicePage3 from './Pages/servicesPages/ServicePage3';
import NotFoundedPage from './Pages/NotFoundedPage';
import ServicePage1 from './Pages/servicesPages/ServicePage1';
import ServicePage2 from './Pages/servicesPages/ServicePage2';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//  { }





function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<Home />} path='/' />
          <Route element={<Contact />} path='/contacts' />
          <Route element={<About />} path='/about' />
          <Route element={<Services />} path='/services' />
          <Route element={<ServicePage3 />} path='/service_details_3' />
          <Route element={<ServicePage2 />} path='/service_details_2' />
          <Route element={<ServicePage1 />} path='/service_details_1' />
          <Route element={<PrivacyPolicy />} path='/privacy_policy' />
          <Route element={<TermsAndConditions />} path='/terms_and_conditions' />
          <Route element={<NotFoundedPage />} path='*' />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
