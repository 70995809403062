import React from 'react'
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import ImageLazyLoading from '../ImageLazyLoading';

const Data  = [ 
  {
    title: "Manutenção de equipamentos de ar comprimido",
    link:"/service_details_1",
    icon:require("../../Assets/Images/icons/1.png"),
    wall:"https://catair.com.au/wp-content/uploads/stainless-steel-pipe-fittings-compressed-air.jpg"
  },
  {
    title: "Assistência  Técnica",
    link:"/service_details_2",
    icon:require("../../Assets/Images/icons/1.png"),
    wall:"https://catair.com.au/wp-content/uploads/maxair-pipe-fittings-compressed-air.jpg"
  },
  {
    title: "Comercialização de equipamentos",
    link:"/service_details_3",
    icon:require("../../Assets/Images/icons/1.png"),
    wall:"https://st.depositphotos.com/1040728/1268/i/450/depositphotos_12680774-stock-photo-pressure-meters-closeup.jpg"
  }, 
];



function ServicesHigh() {
  return (
    <div className='ServicesHigh'>
        <div className="wrapper">
           <div className="title"><h1>Somos Especialistas nos Seguintes Serviços</h1></div>
           <div className="wrap-content">
              {Data.map((item, index)=>{ 
                 return(
                 <div className="card-service">
                     <div className="image">
                         <ImageLazyLoading source={item.wall}  height={300} />
                     </div>
                     <div className="dets">
                        <div className="block">
                             <h5>SERVIÇO V{`${index+1}`}</h5>
                             <Link to={item.link}>
                             <h3>{item.title}</h3>
                             </Link>
                        </div>
                        <div className="space">
                           <div><Link to={item.link}>Saiba mais <FaArrowRightLong /></Link></div>
                           <div className='icon'><ImageLazyLoading source={item.icon} height={90} /></div>
                        </div>
                    </div>
                 </div>);
              })}   
           </div>
        </div>
    </div>
  )
}

export default ServicesHigh